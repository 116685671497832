#rowchoose {
  margin-top: 100px !important;
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
  justify-content: center;
  align-items: center;
}
