#primoblocco {
  display: flex;
  margin-top: 169px;
  width: 1000px;
  text-align: center;
  flex-direction: column;
  flex-wrap: wrap;
  margin-inline: auto;
}
#secondoblocco {
  display: flex;
  margin-top: 148px;
  width: 661px;
  text-align: center;
  flex-direction: column;
  flex-wrap: wrap;
}
.card {
  width: 100px;
  border: 1px solid black;
}
h1 {
  font-family: "Cormorant Garamond", serif !important;
  font-weight: 700 !important;

  font-size: 80px !important;
  letter-spacing: 4px;
}
#insmenu {
  display: flex;
  justify-content: center;
  margin-top: 110px;
}
#primoparagrafo {
  font-family: "PT Serif", serif;
  font-weight: 300;
  width: 700px;
}
#secondoparagrafo {
  font-family: "PT Serif", serif;
  font-weight: 400;
  width: 70%;
  text-align: center;
}
#titlesec {
}
.nons {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  margin-top: 50px;
  text-align: center;
}
#paragrafodinner {
  font-family: "PT Serif", serif;
  font-weight: 400;
  width: 800px;
}
.colore {
  width: 100%;
  background-color: red;
}
#imgdinner {
  border-radius: 50%;
  width: 300px;
  height: 300px;
  object-fit: cover;
  margin-bottom: 50px;
}
.titolo {
  margin-top: 134px !important;
  display: flex;

  text-align: center;
  flex-direction: column;
  align-content: center;
  align-items: center;
}
/* Stili per l'immagine con effetto 3D */
#imgdinner {
  border-radius: 50%;
  width: 300px;
  height: 300px;
  object-fit: cover;
  margin-bottom: 50px;
  transition: transform 0.5s; /* Animazione di transizione per la trasformazione */
  transform-style: preserve-3d; /* Abilita lo stile di trasformazione 3D */
}

/* Effetto 3D al passaggio del mouse */
#imgdinner:hover {
  transform: rotateY(180deg); /* Ruota l'immagine di 180 gradi lungo l'asse Y */
}
