/* BigVideo.css */

.big-video-container {
  width: 100%;
}

.video {
  width: 100%;
  height: auto;
}

.volume-control {
  font-size: 10px;
  padding-top: -2px !important;
  height: 51px;

  padding-top: 0px !important;
  bottom: 13px;
  left: 3px;
  width: 128px;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 7px;
  border-radius: 10px;
  /*  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  top: 234px;
}

.volume-control label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.volume-control input[type="range"] {
  width: 100%;
  -webkit-appearance: none;
  background: #ddd;
  border-radius: 5px;
  height: 5px;
  outline: none;
  margin-top: 5px;
}

.volume-control input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  background: #007bff;
  border-radius: 50%;
  cursor: pointer;
}
