/* .footer-dark {
  padding: 34px 31px 6px;
  color: #f0f9ff;
  background-color: #282d32;
}

.footer-dark h3 {
  margin-top: 0;
  margin-bottom: 12px;

  font-size: 24px;
  letter-spacing: 10px;
  margin-bottom: 26px;
}

.footer-dark h3:hover {
  color: #fff;
}

.footer-dark ul {
  font-family: "Cormorant Garamond";
  font-family: "Cormorant Garamond";
  letter-spacing: 1px;
  padding: 0;
  list-style: none;
  line-height: 2.6;
  font-size: 18px;
  margin-bottom: 0;
}

.footer-dark ul a {
  font-family: "Cormorant Garamond";
  letter-spacing: 1px;
  line-height: 2.6;
  font-size: 18px;
  color: inherit;
  text-decoration: none;
  opacity: 0.6;
  margin-bottom: 0;
}

.footer-dark ul a:hover {
  opacity: 0.8;
  color: #fff;
}

.fab {
  padding: 5px;
  transition: 0.5s;
} */

@media (max-width: 767px) {
  .footer-dark .item:not(.social) {
    text-align: center;
    padding-bottom: 20px;
  }
}

.footer-dark .item2.text {
  margin-bottom: 36px;
}

@media (max-width: 767px) {
  .footer-dark .item2.text {
    margin-bottom: 0;
  }
}

.footer-dark .item2.text p {
  opacity: 0.6;
  margin-bottom: 0;
}

.footer-dark .item.social {
  text-align: center;
}

@media (max-width: 991px) {
  .footer-dark .item.social {
    margin-top: 20px;
  }
}

.footer-dark .item.social > a {
  font-size: 36px;
  width: 70px;
  height: 70px;
  line-height: 65px;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.4);
  margin: 40px 20px;
  color: #fff;
  opacity: 0.75;
  transition: 0.5s;
  transform: scale(0.9);
}

.footer-dark .item.social > a:hover {
  box-shadow: 0 0 15px #fff;
  color: #fff;
}

ul li {
  list-style: none;
  margin: 0 5px;
}

.footer-dark .copyright {
  text-align: center;
  padding-top: 20px;
  opacity: 0.3;
  font-size: 13px;
  margin-bottom: 0;
}

@media (max-width: 500px) {
  .footer-dark .item.social > a {
    height: 69px;
    width: 69px;
    margin: 12px;
    line-height: 65px !important;
    box-shadow: 0 0 0 1px #fff6;
  }
}

#arrowup {
  position: absolute;
  top: 139%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #282d32;
  z-index: 1000;
  width: 169px;
  height: 311px;
  border-radius: 68%;
  display: flex;
  justify-content: center;
}
.social {
  display: flex;
  justify-content: center;
  gap: 59px;
  background: #949494;
  padding: 6px;
}

.admin:hover {
  background-color: #5b5b5b;
  border-radius: 5px;
  padding: 6px;
}

.admin:hover img {
  transform: scale(1.1);
}

@media (max-width: 767px) {
  .footer-dark .item:not(.social) {
    text-align: center;
    padding-bottom: 20px;
  }
}

.footer-dark .item2.text {
  margin-bottom: 36px;
}

@media (max-width: 767px) {
  .footer-dark .item2.text {
    margin-bottom: 0;
  }
}

.footer-dark .item2.text p {
  opacity: 0.6;
  margin-bottom: 0;
}

.footer-dark .item.social {
  text-align: center;
}

@media (max-width: 991px) {
  .footer-dark .item.social {
    text-align: center;
    margin-top: 20px;
    cursor: pointer;
  }
}
.footer-dark {
  background-color: #343a40;
  color: #ffffff;
  font-family: "Arial", sans-serif; /* Set the font for the entire footer */
  padding: 20px 0;
}

#footerxx {
  text-align: center;
}

.footer-column {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.footer-column h3 {
  margin-bottom: 15px;
  font-weight: bold;
}

.footer-column ul {
  list-style: none;
  padding: 0;
}

.footer-column ul li {
  margin-bottom: 10px;
  color: #ffffff;
  text-decoration: none;
  font-family: cursive;
  line-height: 2;
}

.footer-column ul li a {
  color: #ffffff;
  text-decoration: none;
  font-family: cursive;
  line-height: 2;
}

.footer-column ul li a:hover {
  text-decoration: underline;
}

.social {
  margin: 20px 0;
}

.social a {
  color: #ffffff;
  font-size: 24px;
  margin: 0 10px;
  text-decoration: none;
}

.social a:hover {
  color: #d69e79; /* Change this color to the hover color you prefer */
}

.copyright {
  margin: 10px 0 0;
  font-size: 14px;
}

/* Ensure the margin bottom for the final element */
.footer-dark {
  padding-bottom: 40px;
}
#logomio {
  margin-top: -32px;
  width: 170px;
  padding-bottom: -30px;
  margin-bottom: -39px;
}
.footer-dark {
  background: #1d1d1d;
  color: #ffffff;
  font-family: "Arial", sans-serif; /* Imposta il font uniformemente */
  padding: 40px 0;
}

.footer-dark h3 {
  margin-bottom: 32px;
  letter-spacing: 12px;
  font-weight: bold;
}

.footer-dark ul {
  list-style: none;
  padding: 0;
}

.footer-dark ul li {
  margin-bottom: 10px;
}

.footer-dark a {
  color: #ffffff;
  text-decoration: none;
}

.footer-dark a:hover {
  text-decoration: underline;
}

.footer-dark .footer-column {
  margin-bottom: 20px;
}

.footer-dark .admin {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-dark .social a {
  color: #ffffff;
  margin: 0 10px;
  font-size: 20px;
}

.footer-dark #logomio {
  display: block;
  margin: -24px auto;
}

hr {
  border: 1px solid #444;
}
.social a {
  display: inline-block;
  margin: 0 10px; /* Aggiungi spazio tra le icone se necessario */
  font-size: 24px; /* Imposta la dimensione desiderata per le icone */
}
