.button-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.custom-button {
  letter-spacing: 3px;
  width: 228px;
  height: 60px;
  font-size: 18px;
  /* font-weight: bold; */
  color: #333;
  background: linear-gradient(145deg, #e6e6e6, #ffffff);
  border: none;
  border-radius: 10px;
  box-shadow: 5px 5px 10px #ccc, -5px -5px 10px #ffffff;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.custom-button:hover {
  background: linear-gradient(145deg, #ffffff, #e6e6e6);
  box-shadow: 5px 5px 15px #bbb, -5px -5px 15px #ffffff;
  color: #000;
}

.custom-button:active {
  box-shadow: inset 5px 5px 10px #ccc, inset -5px -5px 10px #ffffff;
}

#logorist {
  width: 100px;
  height: auto;
  margin: auto;
}
.custom-link {
  text-decoration: none;
  color: inherit; /* Mantiene il colore del testo */
}

.custom-link:hover,
.custom-link:focus {
  text-decoration: none; /* Rimuove la sottolineatura anche al passaggio del mouse e al focus */
  color: inherit; /* Mantiene il colore del testo anche al passaggio del mouse e al focus */
}
