.navbar-top {
  background-color: transparent !important;
}

.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1020;
}
.navbar-transition {
  transition: background-color 0.3s ease;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-white {
  background-color: white !important;
}
.navbar {
  --bs-navbar-padding-y: 0rem !important;
  font-family: "Comfortaa", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 2px;
  gap: 10px;
}
.text-white {
  color: white !important;
}
.navbar-transition .text-white {
  color: white !important;
}
.navbar-transition .navbar-collapse .text-white,
.navbar-transition .navbar-collapse .text-white > * {
  color: white !important;
}
.bg-white {
  background-color: rgba(255, 255, 255, 0.9); /* Bianco con un'opacità del 90% */
}

.bg-gray {
  background-color: rgba(255, 255, 255, 0.766); /* Grigio con un'opacità del 90% */
}
