#box40 {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
}
.pplate {
  font-size: 18px;
  font-weight: bold;
}
#fuorimenu {
  font-family: "Major Mono Display", monospace !important;
  font-weight: 400;
  font-style: normal;
}
