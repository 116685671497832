.card-container {
  position: relative;
  width: 300px;
  height: 300px;
  perspective: 1000px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.card-front {
  background-color: #fff;
}

.card-container img {
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease-in-out;
  object-fit: cover;
}

.card-container:hover img {
  transform: scale(1.1);
}

.card-container:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}
.card-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.card-container img {
  width: 100%;
  height: auto;
  display: block;
}

.card-container:hover .overlay {
  opacity: 1;
}

card-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.card-container:hover .overlay {
  opacity: 1;
}
.card-container {
  position: relative;
  width: 100%;
  padding-top: 75%;
  overflow: hidden;
  margin-bottom: 30px;
}

.card-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  font-size: 1.5em;
}

.card-container:hover .overlay {
  opacity: 1;
}
