.video-container {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 100%;
  margin: -118px auto !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.video {
  width: 100%;
  height: 100%;
}
#home {
  width: 100%;
  z-index: 1000;
  margin-top: -117px;
}
