.navbar-top {
  background-color: transparent !important;
}

.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1020;
}
.navbar-transition {
  transition: background-color 0.3s ease;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-white {
  background-color: white !important;
}
.navbar {
  --bs-navbar-padding-y: 0rem !important;
  font-family: "Comfortaa", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 2px;
  gap: 10px;
}
.text-white {
  color: white !important;
}
.navbar-transition .text-white {
  color: white !important;
}
.navbar-transition .navbar-collapse .text-white,
.navbar-transition .navbar-collapse .text-white > * {
  color: white !important;
}
/* Navbar.css */
.nav-link {
  color: #333; /* Colore dei link */
  text-decoration: none; /* Rimuove la sottolineatura */
  display: block; /* Per centrare i link */
  padding: 10px 0; /* Spaziatura interna */
  transition: all 0.3s ease; /* Transizione per l'effetto hover */
  text-align: center; /* Per centrare il testo */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Ombra */
}

.nav-link:hover {
  color: #555; /* Cambia colore al passaggio del mouse */
  background-color: #f5f5f5; /* Cambia colore di sfondo al passaggio del mouse */
}

.modal-body {
  padding: 20px; /* Aggiungi spaziatura interna al corpo del modale */
}
.navbar-toggler {
  text-decoration: none;
  background: white;
}
