#primoblocco {
  display: flex;
  margin-top: 117px;
  width: 1000px;
  text-align: center;
  flex-direction: column;
  flex-wrap: wrap;
  margin-inline: auto;
  margin-bottom: 30px;
  align-content: center;
  justify-content: center;
  align-items: center;
}
#secondoblocco {
  display: flex;
  margin-top: 148px;
  text-align: center;
  align-items: center;
}

.card {
  width: 200px;
  border: none !important;
}

h1 {
  text-align: center;
}

#insmenu {
  margin-top: 110px;
  text-align: center;
  margin-bottom: 50px;
}

p {
  font-family: "Sedan SC", serif;
  font-weight: 400;
  font-style: normal;
}

#imghome {
  border-radius: 6px;
  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.5);
  transform: perspective(1000px) rotateY(10deg) rotateX(5deg);
  transition: transform 0.3s ease-in-out;
}

#imghome1 {
  border-radius: 6px;
  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.5);
  transform: perspective(1000px) rotateY(10deg) rotateX(5deg);
  transition: transform 0.3s ease-in-out;
}

.special-button {
  background-color: #638363 !important;
  color: rgb(0, 0, 0) !important;
  font-size: bold !important;
  letter-spacing: 4px;
  border: none;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  width: 300px;
  border-radius: 8px;
  padding: 10px;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.special-button:hover {
  background-color: #45986d;
  color: white;
}

#roow {
  margin-bottom: 100px;
}
@media screen and (max-width: 600px) {
  #imghome {
    transform: none;
    margin-bottom: 30px;
  }
  #imghome1 {
    transform: none;
    margin-bottom: 30px;
  }
}
.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #7e7e7e !important;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #949594 !important;
  padding: 10px;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
