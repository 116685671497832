body {
  overflow-x: hidden;
}
.container-gallery {
  padding: 20px;
}

.row-gallery,
.col-gallery {
  margin-bottom: 20px;
}

.card-gallery {
  border: none;
  height: 300px;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.card-gallery img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  transform: rotateY(20deg);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s ease;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay .text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 24px;
  text-align: center;
  transition: transform 0.3s ease;
}

.card-gallery:hover .overlay {
  opacity: 1;
}

.card-gallery:hover .overlay .text {
  transform: scale(1.2);
}
.card {
  width: 100% !important;
}
.card-gallery img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.card-gallery:hover img {
  transform: rotateY(20deg);
}
.loghi {
  display: flex;
  justify-content: center;
  margin-top: 100px;
}
.gallery-row {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding: 20px;
  gap: 20px;
}

.gallery-row .card-gallery {
  flex: 0 0 auto;
  width: 250px;
}
