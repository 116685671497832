#col {
  display: flex;
  justify-content: center;
  align-items: center;
}
#imghome {
  width: 62% !important;
  margin-top: 165px;
}
#secondoblocco {
  display: flex;
  margin-top: 192px;
  width: 661px;
  text-align: center;
  flex-direction: column;
  flex-wrap: wrap;
}
#imghomepesca {
  margin-top: -7px;
  margin-bottom: 10px;
  width: 62% !important;
  margin-top: 165px;
  height: 354px;
  margin-top: 25px;
}
#roow {
  margin-bottom: 50px;
}
@media screen and (max-width: 1300px) {
  p {
    width: 393px;
  }
  h1 {
    letter-spacing: 20px;
  }
  #imghome {
    height: 354px;
    margin-top: 265px;
  }
  #roow {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
  }
  h1 {
    font-size: 46px !important;
    letter-spacing: 15px;
  }
}
@media screen and (max-width: 1200px) {
  .card-container {
    width: 211px;
  }
}
@media screen and (max-width: 1040px) {
  #secondoblocco {
    display: flex;
    margin-top: 127px;
    width: 411px;
    text-align: center;
    flex-direction: column;
    flex-wrap: wrap;
  }
  h1 {
    font-size: 50px !important;
    letter-spacing: 17px;
  }
  p {
    font-size: 14px;
  }
  h1 {
    margin-top: -100px;
  }
  #imghomepesca {
    margin-top: -7px;
    margin-bottom: 10px;
    width: 62% !important;
    margin-top: 165px;
    height: 354px;
    margin-top: 25px;
  }
}
@media screen and (max-width: 900px) {
}
#colocard {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 700px) {
  #imghome {
    width: 75%;
    margin-top: 233px;
    margin-bottom: 30px;
  }
  h1 {
    font-size: 23px !important;
    letter-spacing: 10px;
  }
  #colocard {
    display: flex;
    justify-content: center;
    height: -112px;
    margin-bottom: -121px;
  }
  #imghomepesca {
    height: 285px;
  }
}
@media screen and (max-width: 500px) {
  p {
    width: 290px;
  }
}
@media screen and (max-width: 400px) {
}
@media screen and (max-width: 300px) {
}
