#appartamenti {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
}
.icona {
  display: flex;
  margin-inline: auto;
  align-content: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 161px;
  margin-top: -53px;
}
#rigacentrale {
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
  align-content: center;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
#colap {
  margin-top: 100px;
}
#immagineap {
  width: 100%;
  margin-top: -82px;
  height: 600px;
  object-fit: cover;
}
.row {
  margin-top: 50px;
}
#logo11 {
  width: "51px";
  margin-right: "24px";
}
#logo12 {
  width: "204px";
}
#logo13 {
  width: "30px";
  margin-left: "14px";
}
#immagineapp {
  width: 100%;
}
@media screen and (max-width: 1200px) {
}
@media screen and (max-width: 1200px) {
  #titolino {
    margin-top: -75px;
    margin-bottom: 29px;
    text-align: center;
  }
  #bigparagrafo {
    width: 92%;
    text-align: center;
  }
}

@media screen and (max-width: 1000px) {
  #immagineap {
    width: 100%;
    margin-top: -82px;
    height: 400px;
    object-fit: cover;
  }
  h1 {
    margin-top: -8px;
  }
  #bigparagrafo {
    font-size: 14px;
    width: 98%;
    text-align: center;
  }
  #colap {
    margin-top: 123px;
    display: flex;
    margin-inline: auto;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 100px;
  }
  #colap {
    margin-top: 123px;
    display: flex;
    margin-inline: auto;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media screen and (max-width: 900) {
  #bigparagrafo {
    font-size: 15px;
    width: 107%;
    text-align: center;
    margin-bottom: 99px;
  }
}

@media screen and (max-width: 700px) {
  #carosel {
    height: 230px !important;
  }
  #immagineapp {
    width: 73%;
    margin-top: -60px;
    margin-bottom: 40px;
  }
  #book {
    padding: 10px;
    font-size: 35px !important;
    margin-top: -148px;
    width: 100%;
    margin-bottom: 80px;
    background: rgba(231, 231, 231, 0.31);
  }

  .icona {
    display: flex;
    margin-inline: auto;
    align-content: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 50px;
    margin-top: -209px;
  }
  #appartamenti {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    margin-top: 78px;
  }
}

@media screen and (max-width: 500px) {
  #immagineap {
    width: 100%;
    margin-top: -82px;
    height: 300px;
    object-fit: cover;
  }
  #apparimg {
    object-fit: cover;
    background-size: cover;
    width: 100%;
    height: 277px;
    margin-top: -65px;
  }
  #immagineapp {
    width: 73%;
    margin-top: -60px;
    margin-bottom: 40px;
  }
  .div {
    margin-top: -29px;
    width: 100%;
    margin-bottom: -51px;
    background: rgba(231, 231, 231, 0.31);
  }

  #carosel {
    height: 194px;
  }
  #logo11 {
    width: "51px";
    margin-right: -12px !important;
  }
  #logo12 {
    width: "204px";
  }
  #logo13 {
    margin-left: -7px !important;
    width: 38px !important;
  }
  #book {
    font-size: 50px !important;
    margin-bottom: -73px;
    letter-spacing: 10px;
  }
  .icona {
    margin-top: -88px;
    display: flex;
    margin-inline: auto;
    align-content: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 50px;
  }
}

.icona {
  display: flex;
  margin-inline: auto;
  align-content: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 50px;
}
