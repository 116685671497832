@media screen and (max-width: 1040px) {
  .titolo {
    margin-top: 234px !important;
    display: flex;

    text-align: center;
    flex-direction: column;
    align-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 800px) {
  #paragrafodinner {
    width: 555px;
  }
}
@media screen and (max-width: 700px) {
  #titolodinn {
    font-size: 40px !important;
  }
  #paragrafodinner {
    width: 426px;
  }
}
@media screen and (max-width: 500px) {
  #titolodinn {
    font-size: 22px !important;
    margin-bottom: -10px;
    letter-spacing: 10px;
    margin-top: -69px;
  }
  #paragrafodinner {
    width: 257px;
  }
  #imgdinner {
    width: 220px;
    height: 220px;
  }
}
