.video-container {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 100%;
  margin: -94px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.video {
  width: 100%;
  height: 100%;
}
