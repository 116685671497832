#carosello {
  border-top: 7px solid red;
  margin-bottom: 100px;
}
#carosello1 {
  border-top: 7px solid cyan;
}
.small-screen {
  display: none !important;
}

@media (max-width: 700px) {
  .large-screen {
    display: none !important;
  }
  .small-screen {
    display: block !important;
  }
}
